.sidebar--active {
  opacity: 1 !important;
  transform: translate3d(0,0,0) !important;
  z-index: $sidebar-active-z-index !important;
}

.sidebar__header {
  padding: 1.75rem 2rem 1.5rem;
  background-color: $sidebar-header-bg;
  position: relative;
  display: flex;
  align-items: center;

  & > h2 {
    font-size: 1.1rem;
    font-weight: $font-weight-normal;
    text-transform: uppercase;
    margin: 0;

    & > small {
      font-size: 0.95rem;
      color: $theme-color-sub;
      text-transform: none;
      display: block;
      margin-top: 0.25rem;
    }
  }

  & > .actions {
    position: absolute;
    top: 1.75rem;
    right: 1.5rem;
  }
}

.sidebar__close {
  width: 40px;
  height: 40px;
  font-size: 2rem;
  background-color: $theme-dark-3;
  border-radius: 50%;
  line-height: 100%;
  margin: 0 1rem 0 0.25rem;
  cursor: pointer;
  @include flex-aligned();

  &:hover {
    background-color: $theme-dark-4;
  }
}

.sidebar-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: $sidebar-active-z-index - 1;
  cursor: pointer;
}