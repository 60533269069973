.dropzone {
  border: 1px solid $input-border-color;
  background-color: transparent;
  border-radius: $border-radius;
  transition: background-color 300ms;
  min-height: 50px;
  position: relative;


  &:before {
    @include font-icon('\ea7c', 2rem);
    font-size: 2rem;
    color: $body-color;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: $theme-dark-1;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 300ms;
  }

  .dz-preview {
    .dz-remove {
      position: absolute;
      top: -9px;
      right: -9px;
      font-size: 0;
      width: 22px;
      height: 22px;
      background-color: $red;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;

      &:hover {
        background-color: $red;
        text-decoration: none;
      }

      &:before {
        @include font-icon('\ea4c', 1.25rem);
        color: $white;
      }
    }

    .dz-image {
      border-radius: 0;
    }
  }

  .dz-message {
    span {
      display: inline-block;
      border-radius: $border-radius-lg;
      transition: color 300ms, box-shadow, 300ms;
      padding: 0.5rem 1.4rem 0.8rem;
      background-color: $theme-dark-5;
      color: $theme-color-heading;

      &:before {
        @include font-icon('\ea7c', 1.75rem);
        display: inline-block;
        position: relative;
        top: 2px;
        margin-right: 0.8rem;
      }
    }
  }

  &:hover,
  &.dz-drag-hover {
    .dz-message span {
      background-color: lighten($theme-dark-5, 5%);
    }
  }

  &.dz-drag-hover {
    .dz-message {
      opacity: 0;
    }

    &:before {
      opacity: 1;
    }
  }
}

