.notifications
{
    opacity:1;
    transform:unset;
    display:none;
    position:fixed;

    &.active
    {
        display:block;
        z-index:1000;
        pointer-events: all;
    }

    &.sidebar--active
    {
        transform:unset;
    }
}