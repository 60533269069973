.stats__info {
  font-weight: $font-weight-light;
  font-size: 2.5rem;
  line-height: 100%;
  margin: 0;
}

.stats__chart {
  height: 100px;
  margin: -50px 0 2px;
}