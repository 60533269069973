@import '../lib/material/scss/app.scss';
@import '~tabulator-tables/dist/css/materialize/tabulator_materialize.css';
@import '~simplebar/dist/simplebar.css';
@import '~react-datetime/css/react-datetime.css';
@import '~react-dropzone-uploader/dist/styles.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~rsuite/dist/styles/rsuite-default.css';


@import 'General';
@import 'Header';
@import 'Sidebar';
@import 'Alert';
@import 'Notification';
@import 'Table';
@import 'Modal';
@import 'Metric';
@import 'Graph';
@import 'DateRangePicker';
@import 'ComponentLoader';
@import 'WidePanel';

@import 'LoginContainer';
@import 'Analytic';