.page-loader.component-loader
{
    position:absolute;
    top:0;
    left:0;

    .loader
    {
        text-align:left;
    }
}