.page-link {
  border-radius: 50% !important;
  width: 37px;
  height: 37px;
  z-index: 1;
  cursor: pointer;
  transition: background-color 300ms, color 300ms;
  margin: 0 2px;
  @include flex-aligned();

  & > i {
    font-size: 1.5rem;
  }
}