.widePanel
{
    max-width: 640px;

    .paragraph
    {
        line-height: 1.85rem;
    }

    form
    {
        max-width: 520px;
        margin: 0 auto;
    }
}