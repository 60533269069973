@media (min-width: 1200px)
{
    .content
    {
        padding-left: 5rem;
        padding-right: 5rem;

        &.with-sidebar
        {
            padding-left: 20rem;
            padding-right: 3rem;
        }
    }

    .logo
    {
        width: 30rem;

        &.with-sidebar
        {
            width: 20rem;
        }
    }
}

.content
{
    .content__title
    {
        padding-left: 0;
        padding-right: 0;

        > h1
        {
            font-size: 1.3rem;
            font-weight: 500;
        }
    }
}

.btn-outline-theme
{
    color: #4d6979;
}

a:hover
{
    color: #b7d3e4;
}