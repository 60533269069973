.analytic
{
    .dateRangePickerWrapper
    {
        display: inline-block;
        position: relative;
        margin-top: 2rem;
        margin-bottom: 2rem;
        background-clip: border-box;
        border: 0 solid rgba(0, 0, 0, .125);
        border-radius: .25rem;
        overflow: hidden;
        transition: box-shadow .3s;
        cursor: pointer;

        &:hover
        {
            -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);

            .rs-picker-daterange
            {
                color: #7996a9;

                .rs-picker-toggle
                {
                    .rs-picker-toggle-value
                    {
                        color: #dcf3ff
                    }

                }

            }
        }

        .page-loader
        {
            position: absolute;
            background-color: #2b3c46;
            text-align: center;

            img
            {
                width: 50%;
                margin-top: -33px;
            }
        }
    }
}