.fc {
  th {
    text-transform: uppercase;
    padding: 0.9rem;
    font-weight: $font-weight-normal;
    color: $theme-color-heading;
  }

  table {
    background: transparent;

    tr {
      & > td {
        &:first-child {
          border-left-width: 0;
          border-bottom-width: 0;
        }

        &:last-child {
          border-right-width: 0;
        }
      }
    }

    thead {
      & > tr > td {
        border-top-width: 0;
      }
    }
  }
}

.fc-unthemed {
  td.fc-today {
    background-color: transparent;

    span {
      color: $red;
    }
  }
}

.fc-event {
  padding: 0;
  font-size: 0.95rem;
  border-radius: 1px;
  background-color: $theme-light;
  border: 0;

  .fc-title {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    display: block;
    color: $white;
    line-height: 100%;
    @include text-truncate();
  }

  .fc-time {
    float: left;
    background: rgba($black, 0.2);
    padding: 0.25rem 0.5rem;
    margin: 0 0 0 -1px;
  }
}

.fc-view {
  &,
  & > table {
    border: 0;
    overflow: hidden;
  }

  & > table {
    & > tbody {
      & > tr {
        .ui-widget-content {
          border-top: 0;
        }
      }
    }
  }
}

.fc-highlight {
  background-color: $theme-dark-1;
}

.fc-button {
  border: 0;
  border-radius: $border-radius;
  padding-bottom: 7px;

  &, .fc-button-primary {
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
      background-color: $theme-dark-5 !important;
    }

    &:focus,
    &:active {
      box-shadow: none !important;
    }
  }
}

// Main Calendar
.calendar {
  &:before {
    content: '';
    display: block;
    transition: background-image 300ms;
    border: 5px solid transparent;
    border-bottom: 0;
    background-clip: content-box;
    @include bg-cover();

    @include media-breakpoint-up(md) {
      height: 250px;
    }

    @include media-breakpoint-down(sm) {
      height: 150px;
    }
  }

  td, th {
    border-color: darken($theme-border-color, 7.5%);
  }

  .fc-day {
    &:hover {
      background-color: $theme-dark-4;
    }
  }

  .fc-day-number {
    @include media-breakpoint-up(sm) {
      font-size: 1.5rem;
    }

    padding: 6px 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  .fc-day-header {
    text-align: left;
  }

  .fc-day-grid-event {
    margin: 1px 9px;
  }
}


// Main Calendar Seasonal headers
@for $i from 1 through 12 {
  .calendar[data-calendar-month="#{$i}"] {
    &:before {
      background-image: url("../../../resources/img/calendar/#{$i}.jpg");
    }
  }
}