.contacts {
  @include media-breakpoint-down(xs) {
    margin: 0 -5px;

    & > [class*="col-"] {
      padding: 0 5px;
    }
  }
}

.contacts__item {
  @include card();
  padding: 1.8rem 1.5rem 1.15rem;
  text-align: center;
  margin-bottom: $grid-gutter-width;

  &:hover {
    .contacts__img > img {
      transform: scale(1.1);
    }
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 10px;
  }
}

.contacts__img {
  display: block;
  margin-bottom: 1.1rem;

  & > img {
    max-width: 120px;
    max-height: 120px;
    width: 100%;
    border-radius: 50%;
    transition: transform 300ms;
  }
}

.contacts__info {
  strong,
  small {
    @include text-truncate();
    display: block;
  }

  strong {
    color: $theme-color-heading;
    font-weight: $font-weight-base;
  }

  small {
    font-size: 0.9rem;
    color: $theme-color-sub;
  }
}

.contacts__btn {
  margin-top: 1rem;
  text-transform: uppercase;
  border: 0;
  line-height: 100%;
  background-color: transparent;
  color: $theme-color-heading;
  cursor: pointer;
  padding: 0.75rem 1rem 0.65rem;
  border-radius: $border-radius;
  transition: background-color 300ms, color 300ms;
  font-size: 0.9rem;

  &:hover {
    background-color: $theme-dark-5;
  }
}

.new-contact__header {
  background-color: $theme-dark-4;
  text-align: center;
  padding: 30px 0;
  border-radius: $card-border-radius $card-border-radius 0 0;
}

.new-contact__img {
  position: relative;
  display: inline-block;

  & > img {
    border-radius: 50%;
    box-shadow: 0 0 0 5px $theme-dark-5;
    @include size(150px, 150px);

    @include media-breakpoint-down(sm) {
      @include size(100px, 100px);
    }
  }
}

.new-contact__upload {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 1.25rem;
  @include size(40px, 40px);
  border-radius: 50%;
  background-color: rgba($theme-dark-1, 0.5);
  color: $white;
  transition: background-color 300ms;
  @include flex-aligned();

  &:hover {
    color: $white;
    background-color: $theme-dark-1;
  }
}