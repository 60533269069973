.rs-picker-daterange,.rs-picker-date
{
    background-color: #2b3c46;
    padding: 0.8rem 0.5rem;
    color: #7996a9;

    .rs-picker-toggle
    {
        &.rs-btn
        {
            background-color: #2b3c46 !important;
            border: 1px solid #2b3c46;
            border-color: #2b3c46 !important;
            padding-left: 30px;
            padding-right: 30px;
        }

        .rs-picker-toggle-caret
        {
            display: none;
        }

        .rs-picker-toggle-placeholder, .rs-picker-toggle-caret, .rs-picker-toggle-value, .rs-picker-toggle-clean
        {
            color: #7996a9;
            font-size: 1.15rem;
            font-weight: 500;
            transition: color 0.3s;
        }

    }

}

.rs-picker-menu
{
    background-color: #f0f6f9;

    .rs-calendar
    {
        background-color: #f0f6f9;
        height: 320px;

        .rs-calendar-header-title
        {
            font-size: 1rem;
            font-weight: 500;
            color: #36576b;
        }

        .rs-calendar-header-backward, .rs-calendar-header-forward
        {
            color: #36576b;
        }

        .rs-calendar-table-cell
        {
            padding: 5px;
            width: 2%;

            .rs-calendar-table-cell-content
            {
                color: #393939;
                border-radius: 50%;

                &:hover
                {
                    background-color: #394e5a;
                    color: #dcf3ff;
                }
            }

            &.rs-calendar-table-cell-is-today
            {
                padding: 5px;

                .rs-calendar-table-cell-content
                {
                    border: 1px solid #394e5a;
                }
            }
        }

        .rs-calendar-table-cell-disabled, .rs-calendar-table-cell-un-same-month
        {
            .rs-calendar-table-cell-content
            {
                color: rgba(121, 150, 169, .5);

                &:hover
                {
                    color: rgba(121, 150, 169, .5);
                }
            }
        }

        .rs-calendar-table-cell-selected
        {
            .rs-calendar-table-cell-content
            {
                background-color: #394e5a;
                color: #dcf3ff;
            }
        }
    }

    /*.rs-picker-daterange-panel
    {
        background-color: #f0f6f9;

        .rs-calendar
        {
            background-color: #f0f6f9;
            height: 320px;

            .rs-calendar-header-title
            {
                font-size: 1rem;
                font-weight: 500;
                color: #36576b;
            }

            .rs-calendar-header-backward, .rs-calendar-header-forward
            {
                color: #36576b;
            }

            .rs-calendar-table-cell
            {
                padding: 5px;
                width: 2%;

                .rs-calendar-table-cell-content
                {
                    color: #393939;
                    border-radius: 50%;

                    &:hover
                    {
                        background-color: #394e5a;
                        color: #dcf3ff;
                    }
                }

                &.rs-calendar-table-cell-is-today
                {
                    padding: 5px;

                    .rs-calendar-table-cell-content
                    {
                        border: 1px solid #394e5a;
                    }
                }
            }

            .rs-calendar-table-cell-disabled, .rs-calendar-table-cell-un-same-month
            {
                .rs-calendar-table-cell-content
                {
                    color: rgba(121, 150, 169, .5);

                    &:hover
                    {
                        color: rgba(121, 150, 169, .5);
                    }
                }
            }

            .rs-calendar-table-cell-selected
            {
                .rs-calendar-table-cell-content
                {
                    background-color: #394e5a;
                    color: #dcf3ff;
                }
            }
        }
    }*/

    .rs-picker-toolbar-right
    {
        padding-top: 30px;

        .rs-picker-toolbar-right-btn-ok
        {
            background-color: #394e5a;
            color: #dcf3ff;

            &:hover
            {
                background-color: #394e5a;
                color: #dcf3ff;
            }
        }
    }

    &.fade.in
    {
        opacity: 1;
    }
}