.past-days {
  overflow: hidden;

  .flot-chart {
    margin: 0 -10px;
  }
}

.past-days__chart {
  opacity: 0.75;
  margin: 0.55rem 0 0 auto;

}

.past-days__info {
  small {
    font-size: 1rem;
    color: $theme-color-sub;
  }

  h3 {
    margin: 0;
    font-weight: normal;
  }
}