.tableContainer
{
    .tableHeader
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 1.5rem;

        .filter
        {
            -webkit-box-flex: 1;
            flex: 1 1 auto;
            font-size: 0;
            appearance: none;
            position: relative;

            label
            {
                width: 100%;
            }

            .tableSearch
            {
                padding: .5rem 1rem .5rem 2.75rem;
                font-size: 1rem;
                border: 1px solid #6c8ea4;
                height: 40px;
                border-radius: .25rem;
                width: 100%;
                background-color: transparent;
                color: #415969;
                -webkit-transition: border-color .3s;
                transition: border-color .3s;


                &::placeholder
                { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    -webkit-transition: color .3s;
                    transition: color .3s;
                    color: #6c8ea4;
                    opacity: 1; /* Firefox */
                }

                &:-ms-input-placeholder
                { /* Internet Explorer 10-11 */
                    -webkit-transition: color .3s;
                    transition: color .3s;
                    color: #6c8ea4;
                }

                &:-ms-input-placeholder
                { /* Microsoft Edge */
                    -webkit-transition: color .3s;
                    transition: color .3s;
                    color: #6c8ea4;
                }

                &:focus
                {
                    border: 1px solid #415969;

                    &::placeholder
                    { /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: #415969;
                        opacity: 1; /* Firefox */
                    }

                    &:-ms-input-placeholder
                    { /* Internet Explorer 10-11 */
                        color: #415969;
                    }

                    &:-ms-input-placeholder
                    { /* Microsoft Edge */
                        color: #415969;
                    }
                }
            }

            &:after
            {
                font-family: zwicon;
                content: '\EA72';
                position: absolute;
                left: 1rem;
                top: .5rem;
                font-size: 1.25rem;
                color: #415969;
            }

            &:focus
            {
                &:after
                {
                    color: #415969;
                }
            }
        }

        .pageLength
        {
            font-size: 0;
            position: relative;
            appearance: none;

            @media (min-width: 576px)
            {
                min-width: 150px;
                margin-left: 1.5rem;
            }

            label
            {
                width: 100%;
            }

            &:after
            {
                font-family: zwicon;
                content: '\EA5b';
                position: absolute;
                left: 1rem;
                top: .5rem;
                font-size: 1.25rem;
                color: #6c8ea4;
            }

            select
            {
                padding: .5rem 1rem .5rem 2.75rem;
                font-size: 1rem;
                border: 1px solid #6c8ea4;
                height: 40px;
                border-radius: .25rem;
                width: 100%;
                background-color: transparent;
                color: #6c8ea4;
                -webkit-transition: border-color .3s;
                transition: border-color .3s;
                -webkit-appearance: none;
                appearance: none;

                &:focus
                {
                    border-color: #6c8ea4;
                }
            }
        }
    }

    .tableHeader.dark
    {
        .filter
        {
            .tableSearch
            {
                border: 1px solid #415969;
                color: #9bbcd1;

                &::placeholder
                { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #7996a9;
                }

                &:-ms-input-placeholder
                { /* Internet Explorer 10-11 */
                    color: #7996a9;
                }

                &:-ms-input-placeholder
                { /* Microsoft Edge */
                    color: #7996a9;
                }

                &:focus
                {
                    border-color: #6c8ea4;
                }
            }

            &:after
            {
                color: #dcf3ff;
            }
        }

        .pageLength
        {
            &:after
            {
                color: #dcf3ff;
            }

            select
            {
                border: 1px solid #415969;
                color: #9bbcd1;

                &:focus
                {
                    border-color: #6c8ea4;
                }
            }
        }
    }

    .tabulator
    {
        background-color: #f0f6f9;

        .tabulator-tableHolder
        {
            .tabulator-placeholder
            {
                span
                {
                    color: #6c8ea4;
                    font-weight: 400;
                    font-size: 1rem;
                    font-style: italic;
                }
            }
        }

        .tabulator-header
        {
            border-bottom: 1px solid #6c8ea4;
            background-color: transparent;

            .tabulator-col
            {
                background-color: #f0f6f9;
                color: #6c8ea4;
                font-weight: 400;
                font-size: 1rem;

                .tabulator-col-content
                {
                    .tabulator-arrow
                    {
                        top: 9px;
                        right: 30px;
                    }
                }

                &.tabulator-sortable[aria-sort=none], &.tabulator-sortable[aria-sort=asc], &.tabulator-sortable[aria-sort=desc]
                {
                    .tabulator-col-content
                    {
                        .tabulator-arrow
                        {
                            border: none;
                        }
                    }
                }

                &:hover
                {
                    background-color: #f0f6f9;

                    &.tabulator-sortable[aria-sort=asc]
                    {
                        .tabulator-col-content
                        {
                            .tabulator-arrow
                            {
                                border: none;

                                &:before
                                {
                                    font-family: zwicon;
                                    font-size: 1.68rem;
                                    line-height: 2.55rem;
                                    content: "\E9E7";
                                }
                            }
                        }
                    }

                    &.tabulator-sortable[aria-sort=desc]
                    {
                        .tabulator-col-content
                        {
                            .tabulator-arrow
                            {
                                border: none;

                                &:before
                                {
                                    font-family: zwicon;
                                    font-size: 1.68rem;
                                    line-height: 2.55rem;
                                    content: "\E9E6";
                                }
                            }
                        }
                    }
                }
            }
        }

        .tabulator-table
        {
            width: 100%;

            .tabulator-row
            {
                background-color: #f0f6f9;
                border-bottom: 1px solid #6c8ea4;

                .tabulator-cell
                {
                    line-height: 200%;

                    span
                    {
                        font-size: 1rem;
                    }

                    &.tabulator-editing
                    {
                        input
                        {
                            color: #7996a9;
                        }
                    }
                }

                &:last-of-type
                {
                    border-bottom: 0;
                }

                &:hover
                {
                    background-color: #f0f6f9;
                }

                .tabulator-responsive-collapse
                {
                    td
                    {
                        padding:1rem;
                    }
                }

                .tabulator-responsive-collapse-toggle
                {
                    background-color:transparent;
                    font-family: zwicon !important;
                    color:#9bbcd1;

                    span
                    {
                        display:none;
                    }

                    &:before
                    {
                        content: "\ea6f";
                        position:absolute;
                    }

                    &.open:before
                    {
                        content: "\ea66";
                    }
                }
            }
        }

        .tabulator-footer
        {
            border-top: 0;
            padding-top: 2rem;

            .tabulator-paginator
            {
                .tabulator-page
                {
                    background-color: #f0f6f9;
                    display: inline-block;
                    color: #6c8ea4;
                    vertical-align: top;
                    border-radius: 50%;
                    margin: 0 1px 0 2px;
                    font-size: 1rem;
                    cursor: pointer;
                    width: 2.5rem;
                    height: 2.5rem;
                    line-height: 2.5rem;
                    text-align: center;
                    transition: background-color 300ms, color 300ms;
                    padding: 0;
                    opacity: 1;
                    border: 1px solid #6c8ea4;

                    &.active
                    {
                        background-color: $pagination-bg;
                        color: $pagination-color;
                        cursor: default;
                        border: 1px solid rgba(0, 0, 0, .12);
                    }

                    &:not(.current):not(.disabled)
                    {
                        &:hover,
                        &:focus
                        {
                            background-color: $pagination-hover-bg;
                            color: $pagination-color;
                            border: 1px solid rgba(0, 0, 0, .12);
                        }
                    }

                    &.current,
                    &.disabled
                    {
                        cursor: default;
                    }

                    &[data-page=prev],
                    &[data-page=next],
                    &[data-page=first],
                    &[data-page=last],
                    {
                        font-size: 0;
                        position: relative;

                        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
                        {
                            font-size: 1rem;
                        }

                        &:before
                        {
                            font-family: $font-family-icon;
                            font-size: 1.68rem;
                            line-height: 2.55rem;
                        }
                    }

                    &[data-page=prev],
                    {
                        &:before
                        {
                            content: '\E90F';
                            //content: '\2B60';
                        }
                    }

                    &[data-page=next],
                    {
                        &:before
                        {
                            content: '\E910';
                            //content:'\2B62'
                        }
                    }

                    &[data-page=first],
                    {
                        &:before
                        {
                            content: '\E92E';
                            //content: '\21E4';
                        }
                    }

                    &[data-page=last],
                    {
                        &:before
                        {
                            content: '\E92F';
                            //content: '\21E5';
                        }
                    }

                    &.disabled
                    {
                        opacity: 0.6;

                        &:hover,
                        &:focus
                        {
                            color: $pagination-color;
                        }
                    }
                }
            }
        }
    }

    .tabulator.dark
    {
        background-color: #2b3c46;

        .tabulator-tableHolder
        {
            .tabulator-placeholder
            {
                span
                {
                    color: #dcf3ff;
                }
            }
        }

        .tabulator-header
        {
            border-bottom: 1px solid #394e5a;
            //border-top: 1px solid #394e5a;

            .tabulator-col
            {
                background-color: #2b3c46;
                color: #dcf3ff;

                &.tabulator-sortable[aria-sort=none]
                {
                    .tabulator-col-content
                    {
                        .tabulator-arrow
                        {
                            border-bottom: 6px solid #dcf3ff;
                        }
                    }
                }

                &:hover
                {
                    background-color: #394e5a;
                }
            }
        }

        .tabulator-table
        {
            .tabulator-row
            {
                background-color: #2b3c46;
                border-bottom: 1px solid #394e5a;

                &:last-of-type
                {
                    border-bottom: 0;
                }

                &:hover
                {
                    background-color: #2b3c46;
                }
            }
        }

        .tabulator-footer
        {
            border-top: 0;
            padding-top: 2rem;

            .tabulator-paginator
            {
                .tabulator-page
                {
                    background-color: $pagination-bg;
                    color: $pagination-color;
                    border: 1px solid rgba(0, 0, 0, .12);

                    &.active
                    {
                        background-color: $pagination-active-bg;
                        color: $pagination-active-color;
                    }

                    &:not(.current):not(.disabled)
                    {
                        &:hover,
                        &:focus
                        {
                            background-color: $pagination-hover-bg;
                            color: $pagination-color;
                        }
                    }

                    &.disabled
                    {
                        opacity: 0.6;

                        &:hover,
                        &:focus
                        {
                            color: $pagination-color;
                        }
                    }
                }
            }
        }
    }

}

table
{
    tr
    {
        td
        {
            &.rowspanColumn
            {
                background-color: #2b3c46;
                border-top-color: #2b3c46;
                border-bottom: 1px solid #394e5a;
            }
        }

        &:nth-last-child(2)
        {
            td
            {
                &.rowspanColumn
                {
                    border-bottom: 0;
                }
            }
        }
    }


}