header.header
{
    .header__main
    {
        .logo
        {
            width: 30rem;

            img
            {
                width:1.7rem;
            }

            &.with-sidebar
            {
                width: 20rem;
            }
        }

        .top-nav
        {
            li
            {
                margin-right: 1.5rem;

                &.with-sidebar
                {
                    margin-right: 0;
                }
            }
        }
    }
}