.sliceToolTips
{
    background: #f0f6f9;
    color: #7996a9;
    font-size: inherit;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;
    padding: 5px 9px;
    min-width: 200px;

    table
    {
        width: 100%;
        border-collapse: collapse;

        tr:first-of-type
        {
            font-weight:500;
        }

        td
        {
            padding: 3px 5px;

            span
            {
                margin-left: 7px;
            }

            /*span
            {
                display: block;
                width: 12px;
                height: 12px;
            }*/
        }

    }
}