.metric
{
    > .card-body
    {
        padding-bottom: 0;

        .card-subtitle
        {
            margin-bottom: 1.5rem;
            font-size: 1.2rem;
            font-weight: 100;

            i
            {
                font-size: 1.45rem;
                line-height: 100%;
            }
        }

        .stats__info
        {
            font-weight: 500;
            color: #dcf3ff;

            &.no-chart
            {
                font-weight: 500;
                font-size: 3rem;
            }
        }
    }

    .row
    {
        margin-left: -30px;
        margin-right: -30px;
    }
}