$toggle-switch-rail-bg: $theme-dark-1;

.toggle-switch {
  display: inline-block;
  width: 36px;
  height: 20px;
  position: relative;
}

.toggle-switch__helper {
  position: absolute;
  height: 12px;
  width: 100%;

  &:before,
  &:after {
    will-change: left, background-color;
    content: '';
    position: absolute;
    left: 0;
    transition: left 200ms, background-color, 200ms;
  }

  &:before {
    background-color: $toggle-switch-rail-bg;
    top: 4px;
    height: 100%;
    width: 100%;
    border-radius: 10px;
  }

  &:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-color: $theme-color-heading;
    left: 0;
    z-index: 1;
  }
}

.toggle-switch__checkbox {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer;

  &:checked {
    & ~ .toggle-switch__helper{
      &:after {
        left: calc(100% - 19px);
        background-color: $blue;
      }
    }
  }

  &:disabled {
    cursor: auto;

    & ~ .toggle-switch__helper {
      opacity: 0.65;
    }
  }

  &:active {
    & ~ .toggle-switch__helper:after {
      box-shadow: 0 0 0 10px rgba($blue, 0.05);
    }
  }
}

// Theming
$toggle-switch-colors: (
  amber: $amber,
  red: $red,
  green: $green,
  purple: $purple,
  orange: $orange
);

@each $color, $value in $toggle-switch-colors {
  .toggle-switch--#{$color} {
    .toggle-switch__checkbox {
      &:checked {
        & ~ .toggle-switch__helper{
          &:after {
            background-color: $value;
          }
        }
      }

      &:active {
        & ~ .toggle-switch__helper:after {
          box-shadow: 0 0 0 10px rgba($value, 0.1);
        }
      }
    }
  }
}