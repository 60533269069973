.lg-outer {
  .lg-thumb-item {
    border-color: rgba($white, 0.25);
    border-radius: $border-radius;
  }
}

.lg-slide {
  em {
    font-style: normal;

    h3 {
      color: $white;
      margin-bottom: 5px;
    }
  }

  .video-cont {
    box-shadow: 0 2px 5px rgba($black, 0.15), 0 2px 10px rgba($black, 0.1);
  }
}