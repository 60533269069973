.login
{
    .login__header
    {
        img
        {
            border-radius: 0;
        }
    }

    .login__btn
    {
        &:focus
        {
            outline: none;
        }
    }

    .invalid-feedback
    {
        margin-top: 1rem;

        &:first-child
        {
            margin-top: 2rem;
        }
    }
}

