$login-header-height: 110px;

.login {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: auto;
  text-align: center;

  &:before {
    content: '';
    height: 35%;
    min-height: $login-header-height;
    width: 100%;
    background-color: #1e2a31;
    display: inline-block;
  }
}

.login__block {
  max-width: 330px;
  width: 100%;
  display: none;
  text-align: center;
  @include animate(fadeIn, 500ms);
  @include card();
  margin-top: -$login-header-height - 6;


  &.active {
    z-index: 10;
    display: inline-block;
  }
}

.login__header {
  height: $login-header-height;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-radius: $border-radius $border-radius 0 0;
  background-color: $theme-dark-2;

  & > i,
  & > img {
    display: block;
    margin-bottom: 0.35rem;
  }

  & > i {
    font-size: 2.75rem;
  }

  & > img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}

.login__block__actions {
  position: absolute;
  bottom: 0.8rem;
  right: 0.8rem;

  .dropdown:not(.show) {
    display: none;
  }
}

.login__body {
  padding: 2rem;

  .form-control {
    position: relative;

    &:focus {
      z-index: 1;
    }
  }

  .form-group {
    margin-bottom: -1px;

    &:not(:only-child) {
      &:first-child {
        .form-control {
          border-radius: $border-radius-lg $border-radius-lg 0 0;
        }
      }

      &:last-child {
        .form-control {
          border-radius: 0 0 $border-radius-lg $border-radius-lg;
        }
      }
    }
  }
}

.login__btn {
  padding: 0.65rem 3rem;
  min-width: 175px;
  background-color: $theme-dark-2;
  border-radius: 25px;
  display: inline-block;
  font-size: 0.9rem;
  margin: 2rem auto 0;
  color: $theme-color;
  transition: color 300ms, background-color 300ms;

  &:hover {
    background-color: $theme-dark-1;
    color: $theme-color-heading;
  }
}

.login__helper {
  font-size: 0.85rem;
  margin-top: 0.5rem;

  & > a {
    color: $theme-color-sub;
    transition: color 300ms;

    &:hover {
      color: $theme-color-heading;
    }
  }
}